import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="bg-white min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-lg p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Welcome to <span className="text-yellow-400">FinVerseBD</span>
        </h1>
        <h2 className="text-xl text-gray-600 mb-8">
          Your Trusted Financial Partner
        </h2>
        <p className="text-lg text-gray-700 mb-8">
          Discover a new era of financial empowerment with <strong className="text-yellow-400">FinVerseBD</strong>. Our tailored solutions and expert guidance will help you achieve your financial goals with confidence and ease. From investments to savings, we're here to guide you every step of the way.
        </p>
        <Link
          to="/Hire"
          className="inline-block bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
        >
          Get Started
        </Link>
      </div>
      <div className="mt-8 text-gray-700">
        <p className="mb-4">Why Choose FinVerseBD?</p>
        <ul className="list-disc pl-4">
          <li>Expert Financial Advice</li>
          <li>Customized Solutions</li>
          <li>Transparent Practices</li>
          <li>24/7 Customer Support</li>
        </ul>
      </div>
      <div className="mt-8 text-gray-700">
        <p className="mb-4">Ready to Transform Your Finances?</p>
        <p className="mb-4">Join FinVerseBD today and embark on a journey towards financial freedom!</p>
        <Link
          to="/Hire"
          className="inline-block bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
        >
          Get Started
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;




