// src/components/Layout.js
import React from 'react';
import Footer from './Footer';

const Layout = ({ children }) => {
    return (
        <div style={layoutStyle}>
            <main style={mainStyle}>{children}</main>
            <Footer />
        </div>
    );
};

const layoutStyle = {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
};

const mainStyle = {
    flex: '1'
};

export default Layout;
