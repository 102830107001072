import React from 'react';

const About = () => {
  return (
    <section className="bg-gradient-to-b from-blue-600 to-indigo-800 py-10 md:py-16 lg:py-20 text-white">
      <div className="container mx-auto text-center">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold mb-4 leading-tight">Empowering Financial Innovation</h1>
        <p className="text-base sm:text-lg md:text-lg lg:text-xl mb-8 leading-relaxed">
          At FinTech Innovate, we're revolutionizing the financial industry with cutting-edge technology and innovative solutions. Our mission is to empower individuals and businesses to thrive in the digital economy.
        </p>
      </div>

      <div className="container mx-auto">
        <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-center">Meet Our Visionaries</h2>
        <div className="flex flex-col sm:flex-row justify-center mb-8 sm:mb-12">
          <div className="team-member bg-white rounded-lg p-4 sm:p-6 shadow-lg text-center mb-4 sm:mb-0 mr-0 sm:mr-4">
            <span className="text-4xl sm:text-5xl lg:text-6xl text-blue-600">K</span>
            <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold text-gray-800 mt-2 sm:mt-4">Kamruzzaman</h3>
            <p className="text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">Co-Founder & CEO</p>
          </div>
          <div className="team-member bg-white rounded-lg p-4 sm:p-6 shadow-lg text-center">
            <span className="text-4xl sm:text-5xl lg:text-6xl text-blue-600">K</span>
            <h3 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold text-gray-800 mt-2 sm:mt-4">Karna</h3>
            <p className="text-sm sm:text-base md:text-lg lg:text-xl text-gray-600">Chief Technology Officer</p>
          </div>
          {/* Add more team members as needed */}
        </div>

        <div className="text-center mb-8 sm:mb-12">
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-4">Our Mission</h2>
          <p className="text-base sm:text-lg md:text-lg lg:text-xl leading-relaxed mx-auto max-w-md">
            Our mission is to create a seamless and inclusive financial ecosystem by providing innovative solutions that empower individuals and businesses to thrive in the digital economy.
          </p>
        </div>

        <div className="text-center">
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-4">Our Values</h2>
          <ul className="text-base sm:text-lg md:text-lg lg:text-xl leading-relaxed mx-auto max-w-md list-disc">
            <li className="mb-2">Innovation: We constantly strive to innovate and push the boundaries of what's possible.</li>
            <li className="mb-2">Empowerment: We empower individuals and businesses to take control of their financial future.</li>
            <li className="mb-2">Collaboration: We believe in the power of collaboration and partnerships to drive meaningful change.</li>
            <li className="mb-2">Transparency: We operate with transparency and integrity in all our interactions.</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default About;


